<!--登录界面-->
<style scoped lang="less">
	html,body{height:100%;}
	.index {
		width: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		text-align: center;
		background-color: #003580;
		font-size: 14px;
		height:100%;
		padding-right:400px;
	}
	.login-bg{background:url(../img/login-bg.jpg) no-repeat center center #000;height:100%;background-size:auto 100%;}
	.login-right{background-color: #fff;width:400px;position: absolute;top:0;right:0;height:100%;}
	.login-main{width:400px;margin:0 auto;position: relative;
		em.bg{background-color: #fff;border-radius: 4px;opacity:1;position: absolute;width: 100%;height: 100%;z-index:1;top:0;left:0;}
	}
	.index h1 {
		font-size:22px;
		color:#37364c;
		margin:120px auto 60px auto;
	}
	.index h2{font-size:20px;padding:20px 0 60px 0;}
	.login-form{width:300px;margin:0 auto;}
	.index .ivu-row-flex {
		height: 100%;
	}
	p.copy{position: absolute;bottom:16px;color:#999;text-align: center;width:100%;font-size:12px;}
</style>
<template>
	<div class="index" @keyup.enter="handleSubmit('formInline')">
		<div class="login-bg"></div>
		<!--<Row clsss="" type="flex" justify="center" align="middle">-->
			<!--<Col class="login-main" span="24">-->
			<!--<em class="bg"></em>-->
			<div class="login-right">
				<h1>
					驾驶员位置信息轨迹（可用于网络货运）
				</h1>
				<h2>登录</h2>
				<Form class="login-form" ref="formInline" :model="formInline" :rules="ruleInline">
					<FormItem prop="user">
						<Input type="text" v-model="formInline.user" placeholder="账号">
						<Icon type="ios-person" slot="prepend"></Icon>
						</Input>
					</FormItem>
					<FormItem prop="password">
						<Input type="password" v-model="formInline.password" placeholder="密码">
						<Icon type="ios-lock" slot="prepend"></Icon>
						</Input>
					</FormItem>
					<FormItem>
						<Button type="success" style="background-color: #0786ff;border-color:#0786ff;" :loading="loading" long @click="handleSubmit('formInline')">登录</Button>
					</FormItem>
				</Form>
				<p class="copy">版权所有 · 北京好运宝科技有限公司 | <a href="//beian.miit.gov.cn/" target="_blank">京ICP备2021006795号-1</a> ©{{new Date().getFullYear()}}</p>
			</div>
			<!--</Col>-->
		<!--</Row>-->
	</div>
</template>
<script>
    import Cookies from 'js-cookie';
    import {ajax} from '../libs/util';
    export default {
        data () {
            return {
                loading: false,
                formInline: {
                    user: '',
                    password: ''
                },
                ruleInline: {
                    user: [
                        { required: true, message: '请填写账号', trigger: 'blur' }
                    ],
                    password: [
                        { required: true, message: '请填写密码', trigger: 'blur' },
                        { type: 'string', min: 6, message: '密码长度不能小于6位', trigger: 'blur' }
                    ]
                }
            }
        },
        methods: {
            handleSubmit (name) {
                let that = this;
                that.$refs[name].validate((valid)=> {
                    if (valid) {
                        that.loading = true;
                        ajax.post('/user/login',{
                            phone: that.formInline.user,
                            password: that.formInline.password,
                        },(res)=> {
                            // if(!res.data.groupName) {
                            //     this.$Message.info('该账号所在用户组已删除，请联系管理员');
                            //     that.loading = false;
                            //     return;
							// }
                            // sessionStorage.authString = res.data.authString;	// 存储权限
                            // Cookies.set('userName', '管理员', {path: '/' });
                            // Cookies.set('userGroup', '全部权限', {path: '/' });
                            Cookies.set('token', res.data.token, {path: '/' });
                            Cookies.set('name', res.data.userinfo.name, {path: '/' });
                        	that.$router.push({ path: 'index' });
                        },(error)=> {
                            that.loading = false;
                            that.$Message.error(error.message || "服务器异常，请重试");
                        });
                    }
                });
            }
        },
		created() {
          	if(Cookies.get('token')) {
                this.$router.push({ path: 'index' });
			}
		},
        mounted() {

        }
    };
</script>
