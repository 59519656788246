// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../img/login-bg.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "html[data-v-a83bd3b0],\nbody[data-v-a83bd3b0] {\n  height: 100%;\n}\n.index[data-v-a83bd3b0] {\n  width: 100%;\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  text-align: center;\n  background-color: #003580;\n  font-size: 14px;\n  height: 100%;\n  padding-right: 400px;\n}\n.login-bg[data-v-a83bd3b0] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center center #000;\n  height: 100%;\n  background-size: auto 100%;\n}\n.login-right[data-v-a83bd3b0] {\n  background-color: #fff;\n  width: 400px;\n  position: absolute;\n  top: 0;\n  right: 0;\n  height: 100%;\n}\n.login-main[data-v-a83bd3b0] {\n  width: 400px;\n  margin: 0 auto;\n  position: relative;\n}\n.login-main em.bg[data-v-a83bd3b0] {\n  background-color: #fff;\n  border-radius: 4px;\n  opacity: 1;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  z-index: 1;\n  top: 0;\n  left: 0;\n}\n.index h1[data-v-a83bd3b0] {\n  font-size: 22px;\n  color: #37364c;\n  margin: 120px auto 60px auto;\n}\n.index h2[data-v-a83bd3b0] {\n  font-size: 20px;\n  padding: 20px 0 60px 0;\n}\n.login-form[data-v-a83bd3b0] {\n  width: 300px;\n  margin: 0 auto;\n}\n.index .ivu-row-flex[data-v-a83bd3b0] {\n  height: 100%;\n}\np.copy[data-v-a83bd3b0] {\n  position: absolute;\n  bottom: 16px;\n  color: #999;\n  text-align: center;\n  width: 100%;\n  font-size: 12px;\n}\n", ""]);
// Exports
module.exports = exports;
