var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "index",
      on: {
        keyup: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.handleSubmit("formInline")
        }
      }
    },
    [
      _c("div", { staticClass: "login-bg" }),
      _c(
        "div",
        { staticClass: "login-right" },
        [
          _c("h1", [_vm._v(" 驾驶员位置信息轨迹（可用于网络货运） ")]),
          _c("h2", [_vm._v("登录")]),
          _c(
            "Form",
            {
              ref: "formInline",
              staticClass: "login-form",
              attrs: { model: _vm.formInline, rules: _vm.ruleInline }
            },
            [
              _c(
                "FormItem",
                { attrs: { prop: "user" } },
                [
                  _c(
                    "Input",
                    {
                      attrs: { type: "text", placeholder: "账号" },
                      model: {
                        value: _vm.formInline.user,
                        callback: function($$v) {
                          _vm.$set(_vm.formInline, "user", $$v)
                        },
                        expression: "formInline.user"
                      }
                    },
                    [
                      _c("Icon", {
                        attrs: { slot: "prepend", type: "ios-person" },
                        slot: "prepend"
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "FormItem",
                { attrs: { prop: "password" } },
                [
                  _c(
                    "Input",
                    {
                      attrs: { type: "password", placeholder: "密码" },
                      model: {
                        value: _vm.formInline.password,
                        callback: function($$v) {
                          _vm.$set(_vm.formInline, "password", $$v)
                        },
                        expression: "formInline.password"
                      }
                    },
                    [
                      _c("Icon", {
                        attrs: { slot: "prepend", type: "ios-lock" },
                        slot: "prepend"
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "FormItem",
                [
                  _c(
                    "Button",
                    {
                      staticStyle: {
                        "background-color": "#0786ff",
                        "border-color": "#0786ff"
                      },
                      attrs: {
                        type: "success",
                        loading: _vm.loading,
                        long: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.handleSubmit("formInline")
                        }
                      }
                    },
                    [_vm._v("登录")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("p", { staticClass: "copy" }, [
            _vm._v("版权所有 · 北京好运宝科技有限公司 | "),
            _c(
              "a",
              { attrs: { href: "//beian.miit.gov.cn/", target: "_blank" } },
              [_vm._v("京ICP备2021006795号-1")]
            ),
            _vm._v(" ©" + _vm._s(new Date().getFullYear()))
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }